<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    import NumberrulelistHS from './numberrulelist-HS/numberrulelistHS.vue';//w选择编号规则
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'form'],
        components: {
            NumberrulelistHS,
        },
        data() {
            return {
                tip_visible: false,
                tip: '',
                file: false,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                dataSourceUnit: [],
                dataSourceContactsName: [],
                dataSourceContactsPhone: [],
                dataSourceDetectionType: [],
                autoData: {},
                fileList: [],
                serial_number: '',
                Numberrulelist: false,
            }
        },
        watch: {
            value(val) {
                console.log('value', val);
            },
        },
        mounted() {
            this.getAutoData()
            this.getList()
        },
        methods: {
            getList() {

            },
            getAutoData() {
                let param = {}
                this.$sa0.file({
                    url: this.$api('核酸检测自动完成数据'),
                    data: param,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.autoData.unit = response.data.unit_name
                            this.autoData.contactsName = response.data.contacts_name
                            this.autoData.contactsPhone = response.data.contacts_phone
                            this.autoData.detectionType = response.data.detection_type
                        },
                        error: (res) => {

                        }
                    })
                })
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            onSubmit() {
                if (this.form.identifier.length === 0) {
                    layer.msg('请输入核酸检测批次编号')
                } else if (this.form.group_person.length === 0) {

                    layer.msg('请输入分组人数')
                } else if (this.input_type === 1 && this.form.file_name.length === 0) {
                    layer.msg('请上传excel')
                } else {
                    this.$sa0.file({
                        url: this.$api('核酸检测项目保存'),
                        data: this.form,
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                if (response.code == 0) {
                                    this.fileList = []
                                    this.$emit('getList')
                                }
                            },
                            error: (res) => {
                            }
                        })
                    })
                }
            },
            //文件上传
            handleChange(info) {
                if (info.file.status === 'uploading') {
                    this.tip = ''
                    this.tip_visible = false
                    this.form.file_name = ''
                }
                if (info.file.status === 'done') {
                    if (info.fileList.length > 1) {
                        info.fileList.splice(0, 1)
                    }
                    let response = info.fileList[0].response
                    if (response.code === 0) {
                        this.form.file_name = response.data.filename
                    } else {
                        this.tip = response.message
                        this.tip_visible = true
                    }
                } else if (info.file.status === 'error') {
                    this.$message.error(`${info.file.name} 上传失败.`);
                }
                this.fileList = [...info.fileList]
            },
            nummoren() {
                this.$sa0.post({
                    url: this.$api('Get_default_serialnumber'),
                    data: {
                        num_type: 4,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.form.identifier = response.data.serialnumber;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            pushOrder(order) {
                this.form.identifier = order
                this.modalCloseHS();
            },
            // 定义 打开对话框时的 函数 // W其它编号规则弹窗
            showModaHS() {
                this.Numberrulelist = true
            },
            modalCloseHS() {
                this.Numberrulelist = false
            },
            onSearchUnit(searchText) {
                this.dataSourceUnit = !searchText ? this.autoData.unit : this.autoData.unit.filter(item => {
                    return item.indexOf(searchText) > -1
                })
            },
            onSearchContactsName(searchText) {
                this.dataSourceContactsName = !searchText ? this.autoData.contactsName : this.autoData.contactsName.filter(item => {
                    return item.indexOf(searchText) > -1
                })
            },
            onSearchContactsPhone(searchText) {
                this.dataSourceContactsPhone = !searchText ? this.autoData.contactsPhone : this.autoData.contactsPhone.filter(item => {
                    return item.indexOf(searchText) > -1
                })
            },

        }
    }
</script>
<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="新增核酸检测" width="45%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-row>
                    <a-col :span="14">
                        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">

                            <a-form-model-item label="检测批次编号" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                                <a-input v-model="form.identifier">
                                    <a-tooltip slot="suffix" title="使用默认编号" @click="nummoren()">
                                        <a-icon type="unordered-list" style="color: rgba(0,0,0,.45)"/>
                                    </a-tooltip>
                                </a-input>
                            </a-form-model-item>

                            <a-form-model-item label="送检单位名称" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                                <a-auto-complete v-model="form.unit_name" :data-source="dataSourceUnit" placeholder="" @search="onSearchUnit"/>
                            </a-form-model-item>

                            <a-form-model-item label="送检联系人" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                                <a-auto-complete v-model="form.contacts_name" :data-source="dataSourceContactsName" placeholder="" @search="onSearchContactsName"/>
                            </a-form-model-item>

                            <a-form-model-item label="送检联系电话" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                                <a-auto-complete v-model="form.contacts_phone" :data-source="dataSourceContactsPhone" placeholder="" @search="onSearchContactsPhone"/>
                            </a-form-model-item>

                            <a-form-model-item label="样品类型" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                                <a-select v-model="form.detection_type">
                                    <a-select-option value="咽拭子">咽拭子</a-select-option>
                                    <a-select-option value="鼻咽拭子">鼻咽拭子</a-select-option>
                                </a-select>
                                <!--                <a-auto-complete :data-source="dataSourceDetectionType" v-model="form.detection_type"-->
                                <!--                                 placeholder=""/>-->
                            </a-form-model-item>

                            <a-form-model-item label="样品组名称" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                                <a-input v-model="form.batch"/>
                            </a-form-model-item>

                            <a-form-model-item label="接收时间" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                                <a-date-picker class="w-full" v-model="form.create_time" show-time format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"/>
                            </a-form-model-item>

                            <a-form-model-item label="分组人数" v-if="form.id===0" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                                <a-input v-model="form.group_person"/>
                            </a-form-model-item>

                            <a-form-model-item label="备注" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                                <a-textarea rows="3" v-model="form.remark"></a-textarea>
                            </a-form-model-item>

                            <a-form-item label="上传excel" v-if="form.id===0" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                                <a-upload name="file" :showUploadList='true' :multiple='false' :action="this.$api('上传核酸检测excel')" @change="handleChange">
                                    <a-button>
                                        <a-icon type="upload"/>
                                        上传excel
                                    </a-button>
                                </a-upload>
                                <p style="color:#ff0000" v-if="tip_visible">{{ tip }}</p>
                            </a-form-item>

                            <a-form-model-item :label-col="{ span: 0, offset: 0 }" :wrapper-col="{ span:15, offset: 8}">
                                <a-button type="primary" @click="onSubmit">保存</a-button>
                                <a-button type="link">下载模板</a-button>
                            </a-form-model-item>

                        </a-form-model>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="" :label-col="{ span: 0, offset: 0 }" :wrapper-col="{ span:12, offset: 0}">
                            <a-button type="primary" size="small" style="width: 100%" @click="showModaHS()">使用其它编号</a-button>
                        </a-form-model-item>
                    </a-col>
                </a-row>

            </div>
        </a-drawer>

        <NumberrulelistHS :callback="pushOrder" :ok="modalCloseHS" :cancel="modalCloseHS" :Numberrulelist="Numberrulelist"></NumberrulelistHS>

    </div>
</template>
<style scoped>
    .w300{
        width: 300px
        }

    .btn{
        position: relative;
        }

    .btn-up{
        display: inline-block;
        position: relative;
        margin-right: 10px;
        padding: 0 15px;
        background: #09aaff;
        color: #fff;
        border: solid 1px #09aaff;
        border-radius: 3px;
        display: inline-block;
        height: 36px;
        line-height: 33px;
        }

    .btn-up input{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        }

    .btn-new{
        display: inline-block;
        position: relative;
        padding: 5px 15px;
        /*border:solid 1px #09aaff;*/
        color: #09aaff;
        background: #fff;
        border-radius: 3px
        }

    .btn-up, .btn-up input, .btn-new{
        cursor: pointer;
        }

</style>
