<template>
  <div>
    <a-form-model layout="inline" :model="formInline">

      <a-form-model-item label="">
        <a-select v-model="formInline.input_type" style="width: 120px" @change="handleChange">
          <a-select-option v-for="item in typeArr" :key="item.key" :value="item.key">{{item.title}}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="">
        <a-select v-model="formInline.status" style="width: 160px" @change="handleChange">
          <a-select-option v-for="item in statusArr" :key="item.key" :value="item.key">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-input v-model="formInline.keywords" class="w350" placeholder="输入送检人单位、联系人、电话、样品组名称"
                 @keyup="getList"></a-input>
      </a-form-model-item>
      <a-form-model-item label="是否审核">
        <a-select style="width: 160px" v-model="formInline.audit" @change="getList">
          <a-select-option :value="1" >全部</a-select-option>
          <a-select-option :value="2">已审核</a-select-option>
          <a-select-option :value="3">未审核</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="是导出大疫情网">
        <a-select style="width: 160px" v-model="formInline.dayiqing" @change="getList">
          <a-select-option :value="1" >全部</a-select-option>
          <a-select-option :value="2">已导出</a-select-option>
          <a-select-option :value="3">未导出</a-select-option>
        </a-select>
      </a-form-model-item>

<!--      <a-form-model-item label="">-->
<!--        <a-button type="primary">新增按钮</a-button>-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="">-->
<!--        <a-space>-->
<!--          <a-checkbox v-model="formInline.audit" @change="getList">否审核完成</a-checkbox>-->
<!--          <a-checkbox v-model="formInline.dayiqing" @change="getList">否导出大疫情网</a-checkbox>-->
<!--        </a-space>-->
<!--      </a-form-model-item>-->

      <a-form-model-item label="">
        <a-button type="primary" @click="clearCondition" size="small">清除搜索</a-button>
      </a-form-model-item>

      <a-form-model-item label="">
        <a-button type="primary" size="small" @click="showDrawer({
        id: 0,
        identifier:'',
        unit_name: '',
        contacts_name: '',
        contacts_phone: '',
        batch: '',
        file_name: '',
        detection_type: '咽拭子',
        group_person: 10,
        create_time: getNowTime(),
        remark:''
      })">
          新增送样检测
        </a-button>
      </a-form-model-item>
    </a-form-model>

<div style="width: 100%; overflow-x: scroll; padding-bottom: 25px">
    <table class="layui-table" lay-size="sm" style="width: 130%">
      <thead>
      <tr>
        <th>操作</th>
        <th>检测批次编号</th>
        <th>类型</th>
        <th>送检单位/任务创建人</th>
        <th>联系信息</th>
        <th>样品组名称</th>
        <th>接收时间</th>
        <th>完成检测时间</th>
        <th>样品总数量</th>
        <th>分组人数</th>
        <th>阴性/阳性数量</th>
        <th>人员总数</th>
        <th>已检验人数</th>
        <th>已审核人数</th>
        <th>状态</th>
        <th>备注</th>
        <th>是否导出大疫情网</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in list" :key="item.id">
        <td>
          <a-space>
            <a-button type="link" size="small" @click="showDrawer(item)" v-if="item.status==1">编辑</a-button>

            <template>
              <a-button size="small" type="link" @click="view(item.id)">检验结果</a-button>
            </template>

            <template v-if="item.status==2 && item.status!=3 && item.auditor_total == item.checker_total">
              <a-popconfirm title="是否要把次记录归档？" @confirm="changeStatus(item.id,3)">
                <a-icon slot="icon" type="question-circle-o" style="color: red"/>
                <a-button size="small" type="link">归档</a-button>
              </a-popconfirm>
            </template>

            <template v-if="item.status!=4">
              <a-popconfirm title="是否要把次记录作废？" @confirm="changeStatus(item.id,4)">
                <a-icon slot="icon" type="question-circle-o" style="color: red"/>
                <a-button size="small" type="link" style="color: #ff0000">作废</a-button>
              </a-popconfirm>
            </template>

          </a-space>
        </td>
        <td>{{ item.identifier }}</td>
        <td>
          <span v-if="item.input_type===1">送检检测</span>
          <span  v-if="item.input_type===2">外采检测</span>
        </td>
        <td>{{ item.unit_name }}</td>
        <td>{{ item.contacts_name }}<br/>{{ item.contacts_phone }}</td>
        <td>{{ item.batch }}</td>
        <td>{{ item.create_time }}</td>
        <td>{{ item.check_time }}</td>
        <td>{{ Math.ceil(item.total_person/ item.group_person)}}</td>
        <td>{{ item.group_person }}</td>
        <td v-if="item.positive != 0" style="color: #ff525d">{{ item.negative }}/{{ item.positive }}</td>
        <td v-else>{{ item.negative }}/{{ item.positive }}</td>
        <td>{{item.total_num}}</td>
        <td>{{ item.checker_total }}</td>
        <td>{{ item.auditor_total }}</td>
        <td>{{ item.status_show }}<br> {{item.update_time}}</td>
        <td>{{ item.remark }}</td>
        <td>
          <span  v-if="item.dayiqing_time">是<br/>{{item.dayiqing_time}}</span>
          <span  v-else>否</span>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
      <a-pagination v-if="list.length>0" v-model="pagination.current" :total="pagination.total" :pageSize="pagination.pageSize" show-less-items @change="onChange"/>
    </div>
    <Addhesuan :close="onCloseDrawer" @getList="getList" :form="info" :visible="drawer_visible"></Addhesuan>
</div>

  </div>
</template>

<script>
import Addhesuan from './addhesuan/addhesuan';
export default {
  name: "hesuanjiance",
  components: {
    Addhesuan
  },
  //['全部样品组','等待检测','检测完成','作废']
  data() {
    return {
      typeArr:[
        {
          key: 0,
          title: '全部类型'
        },
        {
          key: 1,
          title: '送样检测'
        },
        {
          key: 2,
          title: '外出采样'
        }
      ],
      statusArr: [
        {
          key: 0,
          title: '全部全部状态'
        },
        {
          key: 1,
          title: '等待检测'
        },
        {
          key: 2,
          title: '检测完成'
        },
        {
          key: 3,
          title: '已归档'
        },
        {
          key: 4,
          title: '作废'
        }
      ],
      info: {},//详情
      pagination: {
        'current': 1,
        'total': 30,
        'pageSize': 30
      },
      drawer_visible: false,
      formInline: {
        input_type:0,
        status: 1,
        audit:1,
        dayiqing:1,
        keywords: '',
        page: 1
      },
      list: []
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    changeStatus(id, status) {
      let param = {
        id: id,
        status: status
      }
      this.$sa0.file({
        url: this.$api('核酸检测更改状态'),
        data: param,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            location.reload()
          },
          error: (res) => {

          }
        })
      })
    },
    onChange(p) {
      this.formInline.page = p
      this.getList()
    },
    view(id) {
      this.$router.push('/hesuanjiance/jieguo/' + id)
    },
    clearCondition() {
      this.formInline = {
        input_type:0,
        status: 1,
        keywords: '',
        page: 1,
        audit:1,
        dayiqing:1,
      }
      this.getList()
    },
    handleChange(value) {
      this.getList()
    },
    // 定义 关闭抽屉时的 函数
    onCloseDrawer() {
      this.drawer_visible = false;
    },
    // 定义 打开抽屉时的 函数
    showDrawer(item) {
      this.info = JSON.parse(JSON.stringify(item))
      this.drawer_visible = true;
    },
    // 获取当前时间
    getNowTime: function () {
      let dateTime
      let yy = new Date().getFullYear()
      let mm = new Date().getMonth() + 1
      let dd = new Date().getDate()
      let hh = new Date().getHours()
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
      return dateTime
    },
    getList() {
      this.drawer_visible = false
      let param = this.formInline
      this.$sa0.file({
        url: this.$api('核酸检测列表'),
        data: param,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.pagination.current = response.data.pagination.current
            this.pagination.total = response.data.pagination.total
            this.pagination.pageSize = response.data.pagination.pageSize
            this.list = response.data.list
            console.log(this.list)
          },
          error: (res) => {

          }
        })
      })
    }

  },
}
</script>

<style scoped>
  .layui-table th{text-align: left}
.w350 {
  width: 350px
}

</style>
